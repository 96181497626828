import React, { useState } from 'react';
import './TitleText.css'; // Renamed HeaderComponent.css to TitleText.css

const TitleText = () => {
    const [selectedItem, setSelectedItem] = useState('something');
    const items = ['blog', 'application', 'portfolio'];

    return (
        <div className="header-content">
            <h1 className="title">DuckVoid.xyz</h1>
            <h4 className="subtitle">
                a <b>digital</b> <span className="hover-target">{selectedItem}</span> by Denis and Matvey
                <div className="dropdown-menu">
                    {items.map((item, index) => (
                        <div 
                            key={index} 
                            className="dropdown-item" 
                            onClick={() => setSelectedItem(item)}
                            style={{ animationDelay: `${index * 0.1}s` }} // Each item has a slight delay based on its position
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </h4>
        </div>
    );
};

export default TitleText;
