import React from 'react';
import './App.css';
import TitleText from './TitleText'; // Renamed HeaderComponent to TitleText
import LoadingBar from './LoadingBar';
import Sketch from './Sketch'; // This is where we'll use p5.js

function App() {
  return (
    <div className="App">
      <Sketch />
      <LoadingBar />
      <div className="content">
        <TitleText />
      </div>
    </div>
  );
}

export default App;
