import React, { useState, useEffect } from 'react';

const LoadingBar = () => {
    const [width, setWidth] = useState(0);
    const [intervalSpeed, setIntervalSpeed] = useState(20); // Initial interval speed

    useEffect(() => {
        if (width >= 100) {
            // Stop the bar when it reaches 100%
            return;
        }

        // Simulate "ease-in" and "ease-out" by adjusting the interval speed based on the width
        const adjustIntervalSpeed = () => {
            if (width < 50) {
                // "Ease-in" - start slower
                return Math.max(20, intervalSpeed - 1);
            } else {
                // "Ease-out" - end slower
                return Math.min(100, intervalSpeed + 1);
            }
        };

        const handleLoading = () => {
            setWidth((prevWidth) => prevWidth + 1);
            setIntervalSpeed(adjustIntervalSpeed);
        };

        const intervalId = setInterval(handleLoading, intervalSpeed);

        // Clean up the interval on component unmount or when loading completes
        return () => clearInterval(intervalId);
    }, [width, intervalSpeed]); // Depend on `width` and `intervalSpeed` to determine effect changes

    const loadingBarStyle = {
        position: 'fixed',
        top: 0, // Place it at the very top
        left: 0,
        height: '5px',
        width: `${width}%`,
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(to right, #21d4fd, #b721ff)',
        transition: `width ${intervalSpeed}ms linear`,
        zIndex: 3, // Make sure it's above everything else
    };

    return <div style={loadingBarStyle} />;
};

export default LoadingBar;
