import React, { useEffect } from 'react';
import p5 from 'p5';

const Sketch = () => {
  useEffect(() => {
    let myShader;
    let noise;

    new p5((p) => {
      // This function preloads the shader and noise texture
      p.preload = () => {
        // Make sure the paths to your shader and noise image are correct
        myShader = p.loadShader('assets/shader.vert', 'assets/shader.frag');
        noise = p.loadImage('assets/noise.png');        
      };

      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight, p.WEBGL);
        p.noStroke();
      };

      p.draw = () => {
        p.background(0);
        // Apply the shader
        p.shader(myShader);

        // Send the frameCount and the noise texture to the shader
        myShader.setUniform('uFrameCount', p.frameCount);
        myShader.setUniform('uNoiseTexture', noise);

        // Rotate our geometry on the X and Y axes
        p.rotateX(p.frameCount * 0.01);
        p.rotateY(p.frameCount * 0.005);

        // Draw a sphere with tessellation
        p.sphere(p.width / 10, 200, 200);
      };

      p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      };

    }, document.getElementById('p5-container'));

    // Return a cleanup function to remove the sketch when the component unmounts
    return () => {
      document.getElementById('p5-container').innerHTML = '';
    };
  }, []);

  return <div id="p5-container" />;
};

export default Sketch;
